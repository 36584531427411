$(function ()  {

    $('.test__selectcont input').change(function () {

        var
            $thie = $(this),
            thisSib = $thie.siblings('label');

        thisSib.addClass('disabled');
        $('html, body').stop().animate({'scrollTop': 0 }, 500, 'swing', function () { });

        setTimeout(function() {
            $('.activeItem').addClass('strped');
        }, 500);

        setTimeout(function() {
            var
                nextItem = $('.activeItem').next(),
                indexNext = nextItem.index();

            if(!$('.activeItem').hasClass('lastItem')) {
                var
                    nextItem = $('.activeItem').next();

                $('.activeItem').removeClass('activeItem');
                nextItem.addClass('activeItem');
                $('.qnum').text(indexNext + 1);

            } else {
                lastStep();
            }
        }, 1200);


    });

});


function lastStep() {
    $('.loading').addClass('visible');

    count();

    setTimeout(function() {
        $('.result__page').show();
        $('.test__page').hide();
    }, 1000);

    setTimeout(function() {
        $('.loading').removeClass('visible');
    }, 2000);

}


function count() {
    var
        input = $('input:checked');


    var arr1 = [];

    input.each(function () {
        var
            val = $(this).val();

        arr1.push(val.split(' ')[0]);
    });


    var mf = 1;
    var m = 0;
    var item;
    for (var i=0; i<arr1.length; i++)
    {
        for (var j=i; j<arr1.length; j++)
        {
            if (arr1[i] == arr1[j])
                m++;
            if (mf<m)
            {
                mf=m;
                item = arr1[i];
            }
        }
        m=0;
    }

    $('#'+item).addClass('theResult');
}